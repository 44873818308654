import { useState, useEffect } from "react";
import { parseEther } from "viem";

export const useTopUp = () => {
  const [chainId, setChainId] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [MIN_SESSION_WALLET_BALANCE, setMIN_SESSION_WALLET_BALANCE] = useState(parseEther(""));
  const [balanceCheck, setbalanceCheck] = useState("");
  const [rewardInfo, setRewardInfo] = useState("");
  const [recipient, setRecipient] = useState(""); // 设置 recipient 地址
  const [currencySymbol , setCurrencySymbol] = useState("")

  const getChainId = async () => {
    try {
      const chainIdHex = await window.ethereum.request({
        method: "eth_chainId",
      });
      const chainId = parseInt(chainIdHex, 16); 
      setChainId(chainId);
    } catch (error) {
      console.error("Error fetching chainId:", error);
    }
  };

  useEffect(() => {
    getChainId();
    if (window.ethereum) {
      window.ethereum.on('chainChanged', (chainId) => {
        console.log(chainId);
          window.location.reload();
      });
  }
  }, []);

  useEffect(() => {
    if (chainId === 690) {
      setInputValue("0.0003");
      setCurrencySymbol("$BUGS")
      setbalanceCheck('0.000015')
      setRewardInfo("150 $BUGS");
      setMIN_SESSION_WALLET_BALANCE(parseEther("0.0000003"));
      setRecipient("0x784844480280ca865ac8ef89bb554283dddff737"); // 设置 recipient 地址
    } else if (chainId === 31338 || chainId === 31337) {
      setInputValue("10");
      setbalanceCheck('3')
      setCurrencySymbol("$BUGS")
      setRewardInfo("150 $BUGS");
      setMIN_SESSION_WALLET_BALANCE(parseEther("0.03"));
      setRecipient("0xdfa57287c291e763a9452738b67ac56179ab5f69"); // 设置 recipient 地址
    }
  }, [chainId]);

  return {
    inputValue,
    MIN_SESSION_WALLET_BALANCE,
    balanceCheck,
    rewardInfo,
    recipient,
    currencySymbol,
    setInputValue,
  };
};


